/* eslint-disable no-case-declarations */
import React, { useState, useReducer, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { ContextApp } from '@lmig/dotcom-aspect-components/ContextApp';
import { clearCookie } from '@lmig/dotcom-aspect-comparion-components/SystemUtils/WriteStateCookie';
import capSpec from '@lmig/dotcom-aspect-comparion-helpers/specs/cap.spec';
import agentSpec from '@lmig/dotcom-aspect-comparion-helpers/specs/agent.spec';
import { formatPhoneNumber } from "@lmig/dotcom-aspect-comparion-helpers/phoneNumber";

const EntityContext = React.createContext();

const defaultEntitySchema = { active: false, queryType: '', queryValue: '' };

const EntityContextProvider = ({
  cap = defaultEntitySchema,
  agent = defaultEntitySchema,
  isNoIndex: isNoIndexProp = false,
  children
}) => {
  const [isNoIndex, setIsNoIndex] = useState(isNoIndexProp);
  const { setPhones } = useContext(ContextApp);
  const [entities, dispatchEntities] = useReducer(
    (state, options) => {
      switch (options.action) {
        case 'CLEAR':
          clearCookie();

          return {
            cap: defaultEntitySchema,
            agent: defaultEntitySchema,
          };
        default:
          const newObject = Object.entries(options).reduce((acc, [key, value]) => {
            if (value === defaultEntitySchema) {
              return acc;
            }

            return {
              ...acc,
              [key]: value,
            };
          }, {});

          return {
            ...state,
            ...newObject,
          };
      }
    },
    { cap, agent },
  );

  useEffect(() => {
    dispatchEntities({ cap, agent });
  }, [cap, agent]);

  useEffect(() => {
    const drcPhoneNumber = formatPhoneNumber(entities.agent?.primaryPhone?.number || entities.cap?.primaryPhone?.number);

    if (drcPhoneNumber) {
      setPhones({ drc: { number: drcPhoneNumber } });
    } else {
      setPhones({ drc: null });
    }
  }, [entities, setPhones]);

  const memoValue = useMemo(() => ({
    entities,
    dispatchEntities,
    isNoIndex,
    setIsNoIndex,
  }), [entities, isNoIndex]);

  return (
    <EntityContext.Provider value={memoValue}>
      {children}
    </EntityContext.Provider>
  );
};

EntityContextProvider.propTypes = {
  cap: PropTypes.shape(capSpec),
  agent: PropTypes.shape(agentSpec),
  isNoIndex: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

// Then make a consumer which will surface it
const EntityContextConsumer = EntityContext.Consumer;

export { EntityContextProvider, EntityContextConsumer, EntityContext };
