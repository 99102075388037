const { arrayOf, oneOfType, string, bool, shape, object } = require('prop-types');
const imageSpec = require('./image.spec');
const phoneSpec = require('./phone.spec');
const agentSpec = require('./agent.spec');

module.exports = {
  active: bool,
  queryType: string,
  queryValue: string,
  alias: string,
  webId: string,
  partnerId: string,
  name: string,
  landingPage: string,
  greeting: string,
  disclaimer: string,
  image: shape(imageSpec),
  phones: oneOfType([arrayOf(shape(phoneSpec)), object]),
  agents: arrayOf(shape(agentSpec)),
};
