const formatPhoneNumber = num => {
  if (typeof num === 'string' && num.startsWith('1-') && num.length > 12) {
    return num.slice(2);
  }

  return num;
};

module.exports = {
  formatPhoneNumber
};