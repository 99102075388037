const { string, shape } = require('prop-types');

const Phone = shape({
  number: string.isRequired,
  extension: string,
});

module.exports = {
  primary: Phone,
  toll: Phone,
  cell: Phone,
  fax: Phone,
};
