const initDatadogRum = (config = {}) => {
  const { env, version } = config;

  if (env && version) {
    // eslint-disable-next-line global-require
    const { datadogRum } = require('@datadog/browser-rum-slim');
    datadogRum.setGlobalContextProperty('troux_uuid', 'FC1EC1F1-ADEB-4945-9E4E-0F01EE27089E');

    datadogRum.init({
      applicationId: 'f6816842-a892-42d7-8664-abd07bcd04d8',
      clientToken: 'pubc22375e813b10f30ecdd939243cbd807',
      site: 'datadoghq.com',
      service: 'aspect-client-comparion',
      env,
      version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask',
      startSessionReplayRecordingManually: true,
    });
  }
};

module.exports = { initDatadogRum };