const { string, oneOfType, bool, shape, arrayOf, object } = require('prop-types');
const imageSpec = require('./image.spec');
const phoneSpec = require('./phone.spec');

module.exports = {
  firstName: string,
  preferredName: string,
  displayName: string,
  title: string,
  email: string,
  phones: oneOfType([arrayOf(shape(phoneSpec)), object]),
  image: shape(imageSpec),
  bio: string,
  active: bool,
};
